import { nextTick } from "vue";
import { WidgetOptions } from "@/index";
import WidgetApi from "@/modules/widget-api";
import { addCssFromString } from "@/helpers";

function loadCss() {
  // language=CSS
  const css = `
      .call24-wf {
          z-index: 2147483640;
          width: 300px;
          display: flex;
          border: none;
          transition: .2s top, .2s right, .2s bottom, .2s left;
          cursor: pointer;
      }

      .call24-wf.isMobile:not(.expanded) {
          width: 64px;
          height: 64px;
          border-radius: 50%;
      }

      .call24-wf.expanded {
          min-height: min(750px, calc(100dvh - 32px));
          min-width: 450px;
      }

      .call24-wf.expanded.isMobile {
          width: 100%;
          height: 100% !important;
          min-width: 0;
          bottom: 0 !important;
          right: 0 !important;
      }

      .call24-lock-scroll {
          overflow: hidden;
      }
  `;

  addCssFromString(css);
}

/**
 * @returns {Promise<void>}
 */
function widget(options: Partial<WidgetOptions>) {
  window.Call24 = {
    API: new WidgetApi(options),
    PageUrl: (window.document.currentScript as HTMLScriptElement)?.src,
  };

  loadCss();

  nextTick(async () => {
    options.init !== false && (await window.Call24.API.init());
  });
}

widget(window.CALL24_OPTIONS ?? {});
