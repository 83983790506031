import { WidgetOptions } from "@/index";
import {
  IntegrationData,
  InternalAPIMessage,
  MessageAction,
  PageActivity,
  PlatonLoginActionData,
  SetLocaleData,
} from "@/modules/widget-api/types";
import integrations, { allIntegrations } from "@/integrations";
import { App, createApp } from "vue";
import WidgetFrame from "@/WidgetFrame.vue";
import preview from "@/plugins/preview";
import { IRegisterClientResponse, ProjectSettings } from "@/store/types";
import { i18n } from "@/plugins/i18n";

export default class WidgetApi {
  private _app: App<Element> | undefined;
  private _requestId = 0;
  private _ready = false;

  constructor(
    private readonly widgetOptions: WidgetOptions,
    private contentWindow?: WindowProxy
  ) {}

  public setWindow(contentWindow: WindowProxy) {
    this.contentWindow = contentWindow;
  }

  open() {
    this._syncSend({ action: MessageAction.Open });
  }

  async close() {
    return this._asyncSend({ action: MessageAction.Close });
  }

  toggle() {
    this._syncSend({ action: MessageAction.Toggle });
  }

  detach() {
    this._syncSend({ action: MessageAction.Detach });
  }

  attach() {
    this._syncSend({ action: MessageAction.Attach });
  }

  async init() {
    if (this._app) {
      console.warn("Call24: Widget already initialized");
      return;
    }

    const el = document.createElement("div");
    document.body.append(el);

    this._app = createApp(WidgetFrame, {
      widgetOptions: this.widgetOptions,
    });
    this._app.use(preview);
    this._app.use(i18n);
    this._app.mount(el);

    await this._waitForEvent({ source: "call24", action: MessageAction.Init });

    const [projectSettings, userDetails] = await Promise.all([
      this._waitForEvent<ProjectSettings>({
        source: "call24",
        action: MessageAction.Project,
      }),

      this._waitForEvent<IRegisterClientResponse>({
        source: "call24",
        action: MessageAction.VerifyUser,
      }),
    ]);

    this._ready = true;

    integrations({
      projectSettings,
      user: userDetails,
    });
  }

  isInitialized() {
    return this._app !== undefined;
  }

  isDestroyed() {
    return this._app === undefined;
  }

  destroy() {
    if (!this._app) {
      console.warn("Call24: Widget not initialized");
      return;
    }

    this._app?.unmount();
    this._app = undefined;
    this._ready = false;
  }

  setIntegrationData(data: IntegrationData) {
    this._syncSend({
      action: MessageAction.SetIntegrationData,
      data,
    });
  }

  updateIntegrationData(data: IntegrationData) {
    this._syncSend({
      action: MessageAction.UpdateIntegrationData,
      data,
    });
  }

  addClientActivity(data: PageActivity) {
    this._syncSend({
      action: MessageAction.AddClientActivity,
      data,
    });
  }

  async logout() {
    await this._asyncSend({ action: MessageAction.Logout });
    await this.close();
  }

  async platonLogin(data: PlatonLoginActionData) {
    return this._asyncSend({
      action: MessageAction.PlatonLogin,
      data: {
        token: data.token,
        integrationData: allIntegrations.platon.collectUser(
          data.integrationData
        ),
      } as PlatonLoginActionData,
    });
  }

  setLocale(locale: string) {
    this._syncSend<Partial<InternalAPIMessage<SetLocaleData>>>({
      action: MessageAction.SetLocale,
      data: {
        locale,
      },
    });
  }

  private _syncSend<T = Record<string, unknown>>(message: T) {
    if (!this._ready) {
      return;
    }

    this?.contentWindow?.postMessage(
      Object.assign({ source: "call24" }, message),
      "*"
    );
  }

  private async _asyncSend(message: Record<string, unknown>) {
    if (!this._ready) {
      return;
    }

    const currentRequestId = this._requestId++;

    return new Promise((resolve, reject) => {
      if (this?.contentWindow) {
        const clearId = setTimeout(() => {
          window.removeEventListener("message", handler);

          reject();
        }, 60000);

        const handler = (
          e: MessageEvent<{ source: string; responseId: number; data: any }>
        ) => {
          if (
            e.data.source === "call24" &&
            e.data.responseId === currentRequestId
          ) {
            clearInterval(clearId);

            window.removeEventListener("message", handler);
            resolve(e.data.data);
          }
        };

        window.addEventListener("message", handler);

        this.contentWindow?.postMessage(
          Object.assign(
            {
              source: "call24",
              requestId: currentRequestId,
              waitResponse: true,
            },
            message
          ),
          "*"
        );
      }
    });
  }

  private async _waitForEvent<T = unknown>(payload: Record<string, any>) {
    return new Promise<T>((resolve) => {
      window.addEventListener("message", (e) => {
        if (e.data && typeof e.data === "object") {
          if (
            Object.keys(payload).every((key) => payload[key] == e.data[key])
          ) {
            resolve(e.data.data);
          }
        }
      });
    });
  }
}
