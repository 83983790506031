import { computed, onMounted, onUnmounted, ref } from "vue";

let preferWidth = 0;

export function useIsMobile() {
  const isMobile = ref(false);
  const inner = window;

  function handleResize() {
    isMobile.value = isMobileUA();
  }

  const externalListener = (event: MessageEvent<ResizeEvent>) => {
    if (
      typeof event.data === "object" &&
      event.data &&
      event.data.source === "call24"
    ) {
      if (event.data.type === "call24:resize") {
        preferWidth = event.data.width;

        handleResize();
      }
    }
  };

  onMounted(() => {
    inner.addEventListener("resize", handleResize);

    if (window.parent !== window) {
      window.addEventListener("message", externalListener);
      window.parent.postMessage("ask:size", "*");
    }
  });

  onUnmounted(() => {
    inner.removeEventListener("resize", handleResize);

    if (window.parent !== window) {
      window.removeEventListener("message", externalListener);
    }
  });

  handleResize();

  return computed(() => isMobile.value);
}

interface ResizeEvent {
  source: string;
  type: string;
  width: number;
}

export function isMobileUA() {
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    ) || (preferWidth || window.innerWidth) <= 768
  );
}
