export function makeFullPath(path: string | undefined) {
  return window.CALL24_WIDGET.STORAGE_URL + path;
}

export const DEFAULT_AVATAR =
  "https://cdn-icons-png.flaticon.com/512/168/168882.png";

export function getEmployeePhoto(path: string | undefined) {
  return path ? makeFullPath(path) : DEFAULT_AVATAR;
}

export function checkIsPhoto(ext = "") {
  const photoExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
  return photoExtensions.includes(ext);
}

export function isOnlineByDate(date: Date | string | number) {
  if (!date) return false;

  if (typeof date === "string" || typeof date === "number") {
    date = new Date(date);
  }

  const now = new Date();
  const diff = Math.abs(now.getTime() - date.getTime());
  const diffMinutes = Math.ceil(diff / (1000 * 60));
  return diffMinutes < 5;
}

export function addCssFromString(css: string) {
  const style = document.createElement("style");
  style.innerHTML = css;
  document.head.appendChild(style);
}
