import PlatonIntegration, {
  PlatonIntegrationOptions,
} from "@/integrations/platon-integration";
import { IntegrationsContext } from "@/integrations/integration";

export const allIntegrations = {
  platon: new PlatonIntegration(),
} as {
  platon: PlatonIntegration;
};

export default function integrations(context: IntegrationsContext) {
  function tryToInstall(
    integration: "platon",
    options: PlatonIntegrationOptions,
    context: IntegrationsContext
  ) {
    if (allIntegrations[integration].canInstall()) {
      allIntegrations[integration].install(options, context);
    }
  }

  context.projectSettings.integrations?.platon?.enabled &&
    tryToInstall(
      "platon",
      context.projectSettings.integrations.platon,
      context
    );
}
