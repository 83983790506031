<template>
  <div class="chatting__popup" v-show="imagePath && isOpen">
    <div class="chatting__popup--modal" @click="isOpen = false">
      <IconClose
        class="modal-fixed-option-button"
        style="fill: transparent"
        @click="isOpen = false"
      />
      <img
        :src="imagePath"
        alt="image"
        class="modal-fixed-image-content"
        style="cursor: zoom-in"
        :style="{ height: zoom + '%' }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconClose from "@/plugins/preview/IconClose.vue";

import { defineExpose, ref, watch } from "vue";

defineExpose({
  open,
});

const isOpen = ref(false);
const imagePath = ref("");

const escapeHandler = (e: KeyboardEvent) => {
  if (e.key === "Escape") {
    isOpen.value = false;
  }
};

function open(path: string) {
  isOpen.value = true;
  imagePath.value = path;
}

const zoom = ref(80);

function onWheel(e: WheelEvent) {
  zoom.value = Math.min(Math.max(zoom.value + e.deltaY / 100, 10), 90);
}

watch(isOpen, (open) => {
  document.body.classList.toggle("image-viewer-scroll-lock", open);

  if (!open) {
    zoom.value = 80;
  }

  if (open) {
    window.addEventListener("keydown", escapeHandler);
    window.addEventListener("wheel", onWheel);
  } else {
    window.removeEventListener("keydown", escapeHandler);
    window.removeEventListener("wheel", onWheel);
  }
});
</script>
