import { App, createApp, h, inject, VNode } from "vue";
import ImageViewer from "@/plugins/preview/ImageViewer.vue";
import { addCssFromString } from "@/helpers";

export type PreviewerFn = (imgPath: string) => void;

export const Previewer = Symbol("previewer");

// language=CSS
const previewerStlye = `
    .image-viewer-scroll-lock {
        overflow: hidden !important;
    }

    .chatting__popup--modal .modal-fixed-image-content {
        position: absolute;
        max-width: 90%;
        max-height: 90%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 4px;
    }

    .chatting__popup--modal .modal-fixed-option-button {
        position: absolute;
        top: 6%;
        right: 4%;
        height: 3.2rem;
        width: 3.2rem;
        cursor: pointer;
        color: white;
        fill: white;
    }

    .chatting__popup--modal {
        height: 100%;
        width: 100%;
        position: relative;

    }

    .chatting__popup {
        user-select: none;
        position: fixed;
        z-index: 2147483647;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, .7);
    }`;
export default {
  install(app: App) {
    let viewer: VNode | null = null;
    const el = createApp({
      render: () => (viewer = h(ImageViewer)),
    });

    const div = document.createElement("div");
    document.body.append(div);

    el.mount(div);

    addCssFromString(previewerStlye);

    function preview(imgPath: string) {
      viewer?.component?.exposed?.open(imgPath);
    }

    app.provide<PreviewerFn>(Previewer, preview);
  },
};

export function usePreviewer(): { openPreview: PreviewerFn } {
  return {
    openPreview: inject<PreviewerFn>(Previewer) as PreviewerFn,
  };
}
