export enum MessageAction {
  Toggle = "toggle",
  Open = "open",
  Close = "close",
  Detach = "detach",
  Attach = "attach",
  SetIntegrationData = "setIntegrationData",
  UpdateIntegrationData = "updateIntegrationData",
  AddClientActivity = "addClientActivity",
  VerifyUser = "verifyUser",
  Logout = "logout",
  PlatonLogin = "platonLogin",
  Init = "init",
  Project = "project",
  SetLocale = "setLocale",
}

export type InternalAPIMessage<T = undefined> = {
  source: "call24";
  action: MessageAction;
  waitResponse?: boolean;
  requestId?: number;
  data: T;
};

export type PageActivity = {
  url: string;
  title: string;
};

export type SetLocaleData = {
  locale: string;
};

export type PlatonLoginActionData = {
  token: string;
  integrationData: IntegrationData;
};

export type IntegrationData = Record<string, any>;
