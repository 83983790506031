import { createI18n } from "vue-i18n";

import uz from "@/assets/locales/uz.json";
import uzCyrl from "@/assets/locales/uz-cyrl.json";
import ru from "@/assets/locales/ru.json";
import en from "@/assets/locales/en.json";
import ar from "@/assets/locales/ar.json";

const availableLocales = ["uz", "uz-cyrl", "ru", "en", "ar"];
type AppLocale = "uz" | "uz-cyrl" | "ru" | "en" | "ar";
const defaultLocale = "uz";

export function getDefaultLocale(): string {
  const locale = localStorage.getItem("call24_locale");
  if (typeof locale === "string" && availableLocales.includes(locale)) {
    return locale;
  }

  if (window.CALL24_OPTIONS?.locale) {
    const locale = window.CALL24_OPTIONS.locale;
    if (availableLocales.includes(locale)) {
      return locale;
    }
  }

  return defaultLocale;
}

export const i18n = createI18n({
  legacy: false,
  locale: getDefaultLocale(),
  messages: {
    uz,
    ru,
    en,
    ar,
    "uz-cyrl": uzCyrl,
  },
});

export function setLocale(newLocale: string) {
  if (availableLocales.includes(newLocale)) {
    localStorage.setItem("call24_locale", newLocale);
    const html = document.getElementsByTagName("html")[0];
    html.setAttribute("dir", newLocale === "ar" ? "rtl" : "ltr");
    i18n.global.locale.value = newLocale as AppLocale;
  }
}
