import { useDraggable } from "@vueuse/core";
import type { Ref } from "vue";
import { computed, onMounted, toRaw, watch } from "vue";

export interface XYPosition {
  x: number;
  y: number;
}

interface UseBoundaryDraggableOptions {
  screenOffset?: number;
  widgetSize?: Ref<{ width: number; height: number }>;
  initialValue?: XYPosition;
  saveKey?: string;
}

export function useBoundaryDraggable(
  el: Ref<HTMLElement | undefined>,
  options: UseBoundaryDraggableOptions = {}
) {
  const screenOffset = options.screenOffset ?? 0;
  const saveKey = options.saveKey ?? "call24:pos";

  const { position, isDragging } = useDraggable(el, {
    initialValue: options.initialValue,
    pointerTypes: ["mouse", "pen", "touch"],
  });

  const boundaryPosition = computed({
    get() {
      return Object.assign({}, { x: position.value.x, y: position.value.y }, {
        x: Math.max(
          screenOffset,
          Math.min(
            position.value.x,
            window.innerWidth -
              screenOffset -
              (options.widgetSize?.value.width ?? 0)
          )
        ),

        y: Math.max(
          screenOffset,
          Math.min(
            position.value.y,
            window.innerHeight -
              screenOffset -
              (options.widgetSize?.value.height ?? 0)
          )
        ),
      } as XYPosition);
    },

    set(value: XYPosition) {
      position.value = value;
    },
  });

  watch(boundaryPosition, (value) => {
    localStorage.setItem(saveKey, JSON.stringify(toRaw(value)));
  });

  const boundaryStyle = computed(() => {
    return (
      "top: " +
      boundaryPosition.value.y +
      "px; left: " +
      boundaryPosition.value.x +
      "px;"
    );
  });

  function loadPos() {
    const savedPos = localStorage.getItem(saveKey);

    if (savedPos) {
      position.value = Object.assign({}, position, JSON.parse(savedPos));
    }
  }

  loadPos();

  onMounted(() => {
    window.addEventListener("resize", loadPos);

    return () => {
      window.removeEventListener("resize", loadPos);
    };
  });

  return {
    style: boundaryStyle,
    position: boundaryPosition,
    isDragging,
  };
}
