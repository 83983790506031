import Integration, { IntegrationsContext } from "./integration";

declare global {
  interface Window {
    PlatonVue: unknown;
    PlatonPluginCore: PlatonPluginCore;
  }

  interface PlatonPluginCore {
    registerEvent(key: string, callback: () => void): void;

    registerEvent(
      key: "AfterAuth",
      callback: (core: PlatonPluginCore, user: Record<string, unknown>) => void
    ): void;
  }

  interface PlatonPlugin {
    load(core: PlatonPluginCore): void;
  }
}

export interface PlatonIntegrationOptions {
  userIntegrationFields: string;
  identifierNameFromToken: string;
}

export default class PlatonIntegration extends Integration<PlatonIntegrationOptions> {
  _collectFields: string[] = [
    "fullName",
    "mobilePhone",
    "pinfl",
    "postName",
    "id",
    "username",
  ];

  canInstall(): boolean {
    return window.PlatonPluginCore !== undefined;
  }

  collectUser(user: Record<string, unknown>): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    for (const key in user) {
      if (this._collectFields.includes(key) && user[key]) {
        result[key] = user[key];
      }
    }

    return result;
  }

  getPlatonUser() {
    return JSON.parse(localStorage.auth_user || "{}");
  }

  install(
    options: PlatonIntegrationOptions,
    context: IntegrationsContext
  ): void {
    if (options.userIntegrationFields) {
      this._collectFields = options.userIntegrationFields
        .split(",")
        .map((field) => field.trim());
    }

    window.PlatonPluginCore.registerEvent("AfterAuth", async (core, user) => {
      const token = localStorage.getItem("auth_token_refresh");

      if (token)
        await window.Call24.API.platonLogin({
          token,
          integrationData: this.collectUser(user),
        });
      else {
        console.warn("[Call24 Platon integration] Token not found, after auth");
      }
    });

    window.PlatonPluginCore.registerEvent("AfterLogout", async () => {
      await window.Call24.API.logout();
    });

    const platonUser = this.getPlatonUser();
    const token = localStorage.getItem("auth_token_refresh");

    if (
      platonUser[options.identifierNameFromToken] !==
      context?.user?.integrationUserId
    ) {
      if (platonUser.id && token)
        window.Call24.API.platonLogin({
          token,
          integrationData: this.collectUser(platonUser),
        })
          .then(() => {
            console.log("Platon user logged in");
          })
          .catch((e) => {
            console.error("Platon login error", e);
          });
    }
  }

  uninstall(): void {
    return;
  }
}
