import { IRegisterClientResponse, ProjectSettings } from "@/store/types";

export interface IntegrationsContext {
  projectSettings: ProjectSettings;
  user: IRegisterClientResponse;
}

export default abstract class Integration<InstallOptions = undefined> {
  abstract install(options: InstallOptions, context: IntegrationsContext): void;

  abstract uninstall(): void;

  abstract canInstall(): boolean;
}
